import {Html5Qrcode} from "html5-qrcode"

var qrScanner = null;

window.qrScannerInit = function(camera, redirect) {
    let qrScannerData = '';
    let qrScannerTime = 0;
    redirect = redirect || false;

    if ((qrScanner==null) && document.getElementById('qr-scanner')) {
        qrScanner = new Html5Qrcode('qr-scanner');

        const facingMode = (camera==='front' ? 'user' : 'environment');

        qrScanner.start({
            facingMode: facingMode
        }, {
            fps: 5,
            qrbox: {
                width: 250,
                height: 250
            },
            rememberLastUsedCamera: false,
            showTorchButtonIfSupported: true
        }, (decodedText, decodedResult) => {
            const now = +new Date();

            if ((now - qrScannerTime) > (10*1000)) {
                qrScannerData = '';
            }
            qrScannerTime = now;

            if (decodedText !== qrScannerData) {
                qrScannerData = decodedText;

                try {
                    if ((decodedText.substring(0, 8) === 'https://') || (decodedText.substring(0, 7) === 'http://')) {
                        let url = new URL(decodedText);

                        if (redirect && /{uuid}/.test(redirect) && (url.origin === window.location.origin)) {
                            const m = url.pathname.match(/\/badge\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/);
                            if (m) {
                                url = new URL(redirect.replace('{uuid}', m[1]));
                            }
                        }

                        if (qrScanner) {
                            qrScanner.pause(true)
                        }

                        window.location = url.toString();
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }).then(() => {
        });
    }
}

window.qrScannerPause = function() {
    if (qrScanner) {
        qrScanner.pause(true)
    }
}

window.qrScannerResume = function() {
    if (qrScanner) {
        qrScanner.resume()
    }
}




