import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import flatpickr from 'flatpickr';

import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';

import.meta.glob([
    '../img/front/**',
]);

import './common/modal'
import './front/badge-scanner'

window.Alpine = Alpine;

Livewire.start();

/** Touchscreen */
if ('ontouchstart' in window || navigator.maxTouchPoints) {
    document.body.classList.add('is_touchscreen');
}

/**
 * Datepickers
 * Doc. : https://flatpickr.js.org/options/
 */

var current_lang = localStorage && localStorage.getItem('lang').substring(0, 2);

flatpickr('.time-picker', {
    enableTime: true,
    noCalendar: true,
    dateFormat: current_lang === 'fr' ? 'H:i' : 'h:i K',
    time_24hr: current_lang === 'fr'
});

flatpickr('.date-picker-range', {
    mode: 'range',
    wrap: true,
    dateFormat: current_lang === 'fr' ? 'd-m-Y' : 'd-m-Y',
    locale: { rangeSeparator: '  —  ' }
});

/**
 * Swiper carousels
 * Doc. : https://swiperjs.com/swiper-api
 */

new Swiper('.swiper-carousel.swiper-carousel-images', {
    modules: [Autoplay],
    loop: true,
    autoplay: {
        delay: 2000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
    },
});

function initStagesCarousels() {
    new Swiper('.swiper-carousel.swiper-carousel-stages', {
        modules: [Navigation, Pagination, Scrollbar],
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            prevEl: '#prev-news',
            nextEl: '#next-news'
        },
        on: {
            afterInit: function () {
                adjustSlidesPerView(this);
            },
            resize: function () {
                adjustSlidesPerView(this);
            }
        }
    });

    document.querySelectorAll('.swiper-carousel.swiper-carousel-stages').forEach((carousel, index) => {
        carousel.classList.remove('d-none');
    });
}

function adjustSlidesPerView(carousel) {
    const slidesCount = carousel.slides.length;
    let max_slides_per_view = 3;

    if (window.innerWidth >= 1000) {
        max_slides_per_view = 3;

    } else if (window.innerWidth >= 500) {
        max_slides_per_view = 2;
    } else {
        max_slides_per_view = 1;
    }

    carousel.params.slidesPerView = slidesCount < max_slides_per_view ? slidesCount : max_slides_per_view;
    carousel.navigation.nextEl.style.visibility = slidesCount > max_slides_per_view ? 'visible' : 'hidden';
    carousel.navigation.prevEl.style.visibility = slidesCount > max_slides_per_view ? 'visible' : 'hidden';

    carousel.update();
}

initStagesCarousels();

/**
 * Program
 * */

Livewire.on('program-updating', (e) => {
    document.querySelectorAll('.swiper-carousel.swiper-carousel-stages').forEach((carousel, index) => {
        carousel.classList.add('d-none');
    });
});

Livewire.on('program-updated', (e) => {
    setTimeout(function () {
        initStagesCarousels();
    }, 100);
});
